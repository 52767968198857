<template>
  <div class="youtube_video">
    <iframe width="600" height="300" src="https://www.youtube.com/embed/V7huTUjU3YU" title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen></iframe>
  </div>
</template>

<script>

export default ({

  components: {}

})
</script>
<style scoped lang="less">
.youtube_video {
  text-align: center;

  iframe {
    border-radius: 15px;
  }
}
</style>
